<template>
  <v-container>
    <v-row no-gutters class="mb-4">
      <v-col cols="12" class="text-center black--text">
        <p>For Reimu Endou. From Phantomos.</p>
        <p>
          Management: Reimucord Staff<br />
          Programming: jetrico<br />
          Header Artwork: @_YuRaKi_<br />
          Reimu assets (icons, sprites): @Nnnoira<br />
          Web assets editing: Leoje<br />
          Drawing Board: Voldox, Green Tea, Nattsume, Zaraster, Mithra Theos,
            GonzaGKX, Korotony, Hien Anhh, Katha, jetrico<br />
        </p>
        <p>
          <strong>6 Months Compilation Video Credits</strong><br />
          Video edition: Reimu's Gravekeeper<br />
          Intro Video: Enna's Left Feather<br />
          Video Production: GonzaGKX, Reimu's Gravekeeper<br />
          CC ES Translation: Gray_chr<br />
          Thumbnail: Mithra Theos
        </p>
        <p>
          and all phantomos who celebrate these milestones with Reimu and Ethyria!
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: [],
  data: () => ({
    //
  }),
};
</script>

<style lang="scss" scoped>

</style>
